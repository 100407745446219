<template>
  <v-container class="fill-height" fluid>
    <v-row align="start" justify="center" class="fill-height">
      <v-col cols="12">
        <v-card>
          <v-card-title>
            Liste des Lots
          </v-card-title>
          <v-data-table
            locale="fr-FR"
            :headers="headers"
            :items="entities"
            item-key="id"
            class="elevation-1"
            :loading="loading"
            group-by="asset"
          >
            <template v-slot:item.name="{ item }">
              <v-btn
                text
                link
                :to="{ name: 'lots.show', params: { id: item.id } }"
              >
                {{ item.name }}</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { loadStates } from "../utils";

export default {
  name: "Lot",
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "center",
          sortable: false,
          value: "id",
          class: "primary white--text"
        },
        {
          text: "Nom",
          align: "center",
          sortable: false,
          value: "name",
          class: "primary white--text"
        },
        {
          text: "Address",
          align: "center",
          sortable: false,
          value: "address",
          class: "primary white--text"
        },
        {
          text: "Site Web",
          align: "center",
          sortable: false,
          value: "website",
          class: "primary white--text"
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getEntity",
      "getEntityLoadedState",
      "getEntityLoadStatus",
      "getUserProfile",
      "getUserProfileStatus"
    ]),
    loading() {
      return this.getEntityLoadStatus === loadStates.PENDING;
    },
    getLevel4Entities() {
      if (this.$route.params.assetID) {
        return this.getEntity.filter(
          entity =>
            entity.level === 5 &&
            entity.entities_id === +this.$route.params.assetID
        );
      }
      return this.getEntity.filter(entity => entity.level === 5);
    },
    entities() {
      return this.getLevel4Entities.map(entity => {
        return {
          name: entity.name,
          address: entity.address,
          website: entity.website,
          id: entity.id,
          asset: this.getEntityParentName(entity)
        };
      });
    }
  },
  methods: {
    getEntityParentName(entity) {
      let parent = this.getEntity.find(ent => ent.id === entity.entities_id);
      return parent ? parent.name : "";
    }
  }
};
</script>
